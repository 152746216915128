<template>
<div id="app">
    <router-view />
    <app-loading v-if="$store.state.showLoader" />
    <!-- <vue-toastify :your-props="here"></vue-toastify> -->
</div>
</template>

<script>
import Loading from "@/components/common/Loading.vue";
import api2 from "./utils/api2";
import {
    mapState
} from "vuex";

export default {
    computed: {
        ...mapState({
            showLoader: (state) => state.coupon.showLoader,
            rtl_mode: (state) => state.rtl_mode,
            footerData: (state) => state.coupon.footer.footerConfig,
            isHeadTagUpdated: (state) => state.coupon.isHeadTagUpdated,
        }),
    },
    watch: {
        // '$route': function (to, from) {
        //     this.googleVerification()
        // },
        rtl_mode(data) {
            if (this.rtl_mode == 1) {
                // require("./assets/css/bootstrap-rtl.min.css")
                this.$i18n.locale = 'ar';
                import("./assets/css/bootstrap-rtl.min.css")
            } else {
                this.$i18n.locale = 'en';
            }
        },
        footerData(data) {
            if (data.hasOwnProperty('header_html') && !this.isHeadTagUpdated) {
                $('head').append(data.header_html);
            }
            if (data.hasOwnProperty('header_js') && !this.isHeadTagUpdated) {
                $('head').append(data.header_js);
            }
            if (data.hasOwnProperty('favicon') && !this.isHeadTagUpdated) {
                $('#favicon').attr('href', this.$base_url + data.favicon);
                $('#favicon1').attr('href', this.$base_url + data.favicon);
                $('#favicon2').attr('href', this.$base_url + data.favicon);
                $('#favicon3').attr('href', this.$base_url + data.favicon);
            }
            if (data.hasOwnProperty('footer_js') && !this.isHeadTagUpdated) {
                setTimeout(function () {
                    $('head').append(data.footer_js);
                }, 2400);
                this.$store.state.coupon.isHeadTagUpdated = true;
            }

            // var favicon = document.getElementById("favicon");
            document.title = data.name
            let metaDes = document.querySelector('meta[name="description"]');
            let metaTag = document.querySelector('meta[name="keywords"]');
            // favicon.setAttribute("href", "value");
            metaDes.setAttribute("content", data.meta_description);
            metaTag.setAttribute("content", data.meta_tag);
        }
    },
    methods: {
        googleVerification() {
            let url = "https://api.bisharafak.com/api/end-user/website-data/google-verification-tag"
               return api2.get(url)
        }
    },
    created() {
        this.$store.dispatch("coupon/fetchFooter")
    },
    components: {
        "app-loading": Loading,
    },
};
</script>

<style lang="scss">
@import "./assets/css/style.css";
@import "./assets/main";
</style>
